import React from 'react'
import './styles.scss'
import { StaticQuery, graphql } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import Glitch from 'components/glitch'
import Typewriter from 'typewriter-effect'
import ThemeContext from '../../context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import Back from '../../../static/img/backgroundHome.jpg';

class Hero extends React.Component {

  static contextType = ThemeContext

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="hero"
        style={{ height: this.context.height }}
      >
        <Row>
       

          <Col md={7} className="content">
            <div className="content-text">
              <div className="line-text">
                <h4>Hello, I'm</h4>
              </div>
              <h1 className='HomeName'>IBRAHIM RAMZAN</h1>
              
              
              
              <Typewriter
                options={{
                  strings: [
                    'Software Engineer',
                    'Web Developer',
                    'REST API Node.js',
                    'Mobile App Expert',
                    'React.js Expert',
                    'Django Expert'
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
              
            </div>
           
           
          </Col>
          <Col md={5} className="img">
            <img
              src={this.props.mainImg.childImageSharp.fluid.src}
              alt="ibrahim"
            />
             <div className="social social_icons">
                                <FontAwesomeIcon  icon={faGithub} className="social_icon" onClick={() => window.open('https://github.com/ibrahim0099')}/>
                                <FontAwesomeIcon style={{marginTop:15}} icon={faFacebook} className="social_icon" onClick={() => window.open('https://www.facebook.com/ibrahim.ramzan69/')} />
                                <FontAwesomeIcon style={{marginTop:15}} icon={faInstagram} className="social_icon" onClick={() => window.open('https://www.instagram.com/ib.ramzan/')} />
                                <FontAwesomeIcon style={{marginTop:15}} icon={faLinkedin} className="social_icon" onClick={() => window.open('https://www.linkedin.com/in/ibrahim-ramzan-63a59b161/')} />
                            </div>
          </Col>
        </Row>
      </section>
    )
  }

  icons() {
    return this.props.icons.edges.map((value, index) => {
      return (
        <img
          src={value.node.childImageSharp.fluid.src}
          className={`animated fadeIn move-${
            Math.floor(Math.random() * 10) % 2 === 0 ? 'up' : 'down'
          } float-image`}
          style={{
            left: `${index * 10}%`,
            bottom: `${Math.random() *
              (+(index % 2 === 0 ? 80 : 20) - +(index % 2 === 0 ? 70 : 10)) +
              +(index % 2 === 0 ? 70 : 10)}%`,
          }}
          alt="shape"
          key={index}
        />
      )
    })
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile(
          filter: {
            extension: { regex: "/(png)/" }
            relativeDirectory: { eq: "icons" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
        Img: file(relativePath: { eq: "ibrahim.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              src
            }
          }
        }
      }
    `}
    render={({ icons, Img }) => <Hero icons={icons} mainImg={Img} {...props} />}
  />
)
